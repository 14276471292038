import axios from "../lib/axios";
const registerSw = async () => {
    if ('serviceWorker' in navigator) {
        const reg = await navigator.serviceWorker.register('/sw.js');
        initialiseState(reg)

    } else {
        // showNotAllowed("No puedes enviar notificaciones push ☹️😢")
    }
};

const initialiseState = (reg) => {
    if (!reg.showNotification) {
        showNotAllowed('Las notificaciones push no son soportadas ☹️😢');
        return
    }
    if (Notification.permission === 'denied') {
        //showNotAllowed('No nos diste permiso para enviarte notificaciones ☹️🤔');
        return
    }
    if (!('PushManager' in window)) {
        showNotAllowed("Tu navegador no soporta notificaciones push 🤔");
        return
    }
    subscribe(reg);
}

const showNotAllowed = (message) => {
    alert(message)
    return
    const button = document.querySelector('form>button');
    button.innerHTML = `${message}`;
    button.setAttribute('disabled', 'true');
};

function urlB64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    const outputData = outputArray.map((output, index) => rawData.charCodeAt(index));

    return outputData;
}

const subscribe = async (reg) => {
    const subscription = await reg.pushManager.getSubscription();
    if (subscription) {
        sendSubData(subscription);
        return;
    }

    const vapidMeta = document.querySelector('meta[name="vapid-key"]');
    const key = vapidMeta.content;
    const options = {
        userVisibleOnly: true,
        // if key exists, create applicationServerKey property
        ...(key && {applicationServerKey: urlB64ToUint8Array(key)})
    };

    const sub = await reg.pushManager.subscribe(options);
    sendSubData(sub)
};

const sendSubData = async (subscription) => {
    const browser = navigator.userAgent.match(/(firefox|msie|chrome|safari|trident)/ig)[0].toLowerCase();
    const data = {
        status_type: 'subscribe',
        subscription: subscription.toJSON(),
        browser: browser,
    };

    

    // const res = await fetch('/webpush/save_information', {
    //     headers:{
    //         "Authorization": "Bearer " + localStorage.getItem("user_token"),
    //     },
    //     method: 'POST',
    //     body: JSON.stringify(data),
    //     headers: {
    //         'content-type': 'application/json'
    //     },
    //     credentials: "include"
    // });
    const res = await axios.post('/api/webpush/save_information', data)

    handleResponse(res);
};

const handleResponse = (res) => {
    if(res.status === 200){
        localStorage.setItem("subscribed",true);
    }
};

// document.addEventListener('DOMContentLoaded',e=>{
//     if(localStorage.getItem("user_token")){
//         registerSw();
//     }
// })

export {
    registerSw
}