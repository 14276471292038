import { useEffect, useState } from "react";
import useUserService from "../../services/useUserService";
import UserManagementView from "../UserManagementView";
import { useParams } from "react-router-dom";
import LoadingView from "../LoadingView";
import NotFoundView from "../NotFoundView";
import moment from "moment";
import useUserStore from "../../store/useUserStore";
import { ROL } from "../../utils/constantes";


const UserEditView = () => {
    const { getUser, listarRoles } = useUserService()
    const [user, setUser] = useState()
    const [roles, setRoles] = useState([])
    let { id } = useParams()
    const [cargando, setCargando] = useState(true)
    const { user_info } = useUserStore()

    const es_admin = user_info.roles.some(r => r.nombre === ROL.admin)
    useEffect(() => {
        setCargando(true)
        async function cargarDatos() {
            const [r, result] = await Promise.all([
                listarRoles({}),
                getUser({ id: id || user_info.id })
            ])
            if (result) {
                result.fecha_nacimiento = moment(new Date(result.fecha_nacimiento)).format('YYYY-MM-DD');
                result.actualizar_contrasenia = false;
                setUser(result)
                setRoles(r)
            } else {
                setUser(null)
            }
            setCargando(false)
        }
        cargarDatos()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    if (cargando) {
        return <LoadingView />
    } else if (user) {
        return <UserManagementView user={user} editando={true} redirect_to={es_admin ? "/admin/usuarios" : "/profile"} editing_by_admin={es_admin} roles={roles} />
    } else {
        return <NotFoundView />
    }

}

export default UserEditView;