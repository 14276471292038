import { useEffect, useState } from "react";
import useUserService from "../../services/useUserService";
import UserManagementView from "../UserManagementView";
import LoadingView from "../LoadingView";


const UserCreationView = () => {
    const { listarRoles } = useUserService()
    const [roles, setRoles] = useState([])
    const [cargando, setCargando] = useState(true)
    useEffect(() => {
        setCargando(true)
        async function cargarDatos() {
            const result = await listarRoles({})
            setRoles(result)
            setCargando(false)
        }
        cargarDatos()

    }, [])
    if (cargando) {
        return <LoadingView />
    } else{
        return <UserManagementView editando={false} creating_by_admin={true} redirect_to="/admin/usuarios" roles={roles}/>
    }

}

export default UserCreationView;