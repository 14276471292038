import ApartmentForm from "../../components/apartments/ApartmentForm"
import AppLayout from "../../components/layouts/AppLayout"
import Head from "../../components/layouts/Head"
import { Contenedor, Subtitulo } from "../../components/layouts/simpleLayouts"


const NewApartmentView = () => {

    const handleSubmit = (apartment) =>{
        console.log(apartment)
        return true
    } 
   
    return <AppLayout>
        <Head title="Nuevo departamento" />
        <Contenedor>
            <Subtitulo>Nuevo departamento</Subtitulo>
            <ApartmentForm editing={false} onSubmit={handleSubmit}/>
            {/* <AutofillCheckoutDemo/> */}
            
        </Contenedor>
    </AppLayout>
}

export default NewApartmentView