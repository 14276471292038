import { useState } from "react"
import AppLayout from "../../components/layouts/AppLayout"
import Head from "../../components/layouts/Head"
import { Contenedor, Subtitulo } from "../../components/layouts/simpleLayouts"
import ServiceForm from "../../components/services/ServiceForm"
import useServicioService from "../../services/useServicioService"
import Swal from "sweetalert2"
import { useLoaderData, useNavigate, useParams } from "react-router-dom"
import { toast } from "sonner"


export default function ServiceEditView(){
    const [errors,setErrors] = useState([])
    const [currentService,setCurrentService] = useState();
    const {tipos_de_costos} = useLoaderData()
    const {updateService,showService,uploadImage,deleteImage} = useServicioService()
    const navigate = useNavigate()
    const {slug} = useParams()
    useState(()=>{
        showService({slug}).then(result=>{
            if(result){
                setCurrentService(result)
            }else{
                navigate("/404")
            }
        })
    },[])
    async function submit(data){
        setErrors([])
        const result = await updateService(data)
        if(result.status === 200){
            Swal.fire({
                text:"El servicio se ha actualizado con éxito",
                icon:"success"
            }).then(()=>{
                navigate("/admin/servicios")
            })
        }else{
            setErrors(result.data.errors)
        }
    }
    async function handleUploadImage(imagen){
        var result =await uploadImage({imagen,"servicio_id":currentService.id})
        setErrors([])
        if(result.status === 200){
            toast.success("La imagen se ha subido con éxito")
            showService({slug}).then(result=>setCurrentService(result))
        }else if(result.status === 422){
            setErrors(result.data.errors)
        }
    }
    async function handleDeleteImage(imagen){
        console.log({imagen})
        var result = await deleteImage({"id":imagen.id})
        if(result.status === 200){
            toast.success("La imagen se ha eliminado con éxito")
            showService({slug}).then(result=>setCurrentService(result))
        }else if(result.status === 422){
            toast.error("No se pudo eliminar la imagen")
        }
    }
    return <AppLayout>
        <Head title={currentService?.nombre+" - Editar"} />
        <Contenedor>
        <Subtitulo>{currentService?currentService?.nombre+" - Editar":"cargando..."}</Subtitulo>
        {currentService && <ServiceForm 
            editMode={true} errors={errors} 
            obj_service={currentService} 
            onSubmit={submit}
            onImageSelected={handleUploadImage} 
            onDelete={handleDeleteImage}
            tipos_de_costo={tipos_de_costos}
        />}
        </Contenedor>
    </AppLayout>
}