import { useRef, useState } from "react"
import { useId } from "react"

function Image({}){

}

function ImageInput({onChange=()=>{}}){
    const imageId = useId()
    const referencia = useRef()
    function handleImageSelected(ev){
        if(ev.target.files?.length>0){
            let archivo = ev.target.files[0]
            onChange(archivo)
            referencia.current.value=""
        }
    }
    return <input ref={referencia} 
        type="file" accept="image/*" id={imageId} onChange={handleImageSelected}
        className="form-control"
    />
    return <div className="image-container">
        <div className="image-input-container" >
        <input type="file" accept="image/*" id={imageId} onChange={handleImageSelected}/>
        {/* <label htmlFor={imageId}><img  src="/images/upload_icon.png" alt="Agregar imagen"/></label> */}
        </div>
    </div>
}
function ImageView({imagen,onDelete=()=>{}}){
    return <div className="image-container">
        <span className="image-delete-btn"  onClick={()=>onDelete(imagen)}><i className="fa-solid fa-xmark"></i></span>
        <img src={imagen.url} className="image"/>
    </div>
}

function ImageList({children}){
    return <div className="image-list">
        {children}
    </div>
}


export {
    Image,ImageInput,ImageView,ImageList
}