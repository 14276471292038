import AppLayout from "../components/layouts/AppLayout";
import Head from "../components/layouts/Head";
import { Col, FormGroup, Input, InputGroup, InputGroupText, Row } from "reactstrap";
import Label from "../components/layouts/forms/Label";
import { Contenedor, Subtitulo } from "../components/layouts/simpleLayouts";
import { useState } from "react";
import useUserService from "../services/useUserService";
import FormError from "../components/layouts/forms/FormError";
import { toast } from "sonner";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { EmailInput, PasswordInput } from "../components/layouts/forms/Inputs";
import useUserStore from "../store/useUserStore";
import Select from 'react-select'
import { SpinnerRight } from "../components/layouts/forms/Layouts";

const RegisterView = ({ user, editando = false, creating_by_admin = false, editing_by_admin = false,
    redirect_to = "/login", roles = [] }) => {
    const { user_info, setCurrentUser } = useUserStore()
    const [errors, setErrors] = useState([])
    const [user_data, setUserData] = useState(user || {
        nombre: "", apellidos: "", email: "", telefono: "", rut: "", genero: "", fecha_nacimiento: "",
        password: "", password_confirmation: "", as_admin: creating_by_admin
    })
    const [enviando,setEnviando] = useState(false)

    const handleValueChange = (ev) => {
        var { name, type, value, checked } = ev.target
        if (type === "checkbox") {
            value = checked
        }
        setUserData({ ...user_data, [name]: value });

    }

    const { registerUser, updateUser } = useUserService()
    const navigate = useNavigate()

    const getSuccessMessage = () => {
        if (editando) {
            return user_info.id === user_data.id ? "Su cuenta ha sido actualizada con éxito" :
                "La cuenta del usuario ha sido actualizada con éxito";
        } else {
            return creating_by_admin ? "La cuenta del usuario ha sido creada con éxito" :
                "Su cuenta se ha creado con éxito";
        }
    };

    const handleSubmit = async (ev) => {
        ev.preventDefault();
        setEnviando(true)
        var success_message = getSuccessMessage();
        var result;
        if (!editando) {
            result = await registerUser(user_data)
        } else {
            result = await updateUser(user_data)
        }
        setEnviando(false)

        if (result.status === 200) {
            if (editando && user_info.id === result.data.id) {
                const user = result.data
                localStorage.setItem("user_info", JSON.stringify(user))
                setCurrentUser(user)

            }
            Swal.fire({
                text: success_message,
                icon: "success"
            }).then(navigate(redirect_to))
        }
        else if (result.status === 422) {
            toast.error("Algunos campos no son válidos")
            setErrors(result.data.errors)
        } else if (result.data.status === 500) {
            toast.error(result.data.message)
        }


    }

    return <AppLayout>
        <Head
            title={user ? `${user.nombre} ${user.apellidos} | Editar` : (creating_by_admin ? "Nuevo usuario" : "Regístrate")}
        />
        <Contenedor>
            <Subtitulo>{user ? `${user.nombre} ${user.apellidos} | Editar` : (creating_by_admin ? "Nuevo usuario" : 'Creación de cuenta')}</Subtitulo>
            <form onSubmit={handleSubmit} autoComplete="off">
                <Row>
                    <Col md="6">
                        <FormGroup>
                            <Label required={true} for="txt_nombre">Nombre:</Label>
                            <Input type="text" name="nombre" required id="txt_nombre" value={user_data.nombre}
                                onChange={handleValueChange}
                            />
                        </FormGroup>
                        <FormError messages={errors.nombre} />
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label required={true} for="text_apellidos">Apellidos:</Label>
                            <Input type="text" name="apellidos" required id="text_apellidos" value={user_data.apellidos}
                                onChange={handleValueChange}
                            />
                        </FormGroup>
                        <FormError messages={errors.apellidos} />
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <FormGroup>
                            <Label for="fecha_de_nacimiento" required>Fecha de nacimiento</Label>
                            <Input id="fecha_de_nacimiento" value={user_data.fecha_nacimiento}
                                type="date" name="fecha_nacimiento"
                                required onChange={handleValueChange}
                            />
                        </FormGroup>
                        <FormError messages={errors.fecha_nacimiento} />
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label required={true} for="txt_rut">Rut</Label>
                            <Input type="text" name="rut" required id="txt_rut" value={user_data.rut}
                                onChange={handleValueChange}
                            />
                        </FormGroup>
                        <FormError messages={errors.rut} />
                    </Col>
                </Row>

                <Row>
                    <Col md="6">
                        <FormGroup>
                            <Label required={false} for="text_telefono">Teléfono:</Label>
                            <InputGroup>
                                <InputGroupText>+56 9</InputGroupText>
                                <Input type="tel" name="telefono" required id="text_telefono" value={user_data.telefono}
                                    onChange={handleValueChange}
                                    minLength="8" maxLength="8"
                                />
                            </InputGroup>
                            <FormError messages={errors.telefono} />
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label required={true} for="text_correo">Correo:</Label>
                            <EmailInput name="email" required id="text_correo" value={user_data.email}
                                onChange={handleValueChange}
                            />
                        </FormGroup>
                        <FormError messages={errors.email} />
                    </Col>

                </Row>
                {
                    (editando || creating_by_admin) && <>
                        <Row>
                            {(creating_by_admin || editing_by_admin) && <><Col md="6">
                                <Label required={true} for="roles">Roles</Label>
                                <Select
                                    isMulti={true}
                                    placeholder="Seleccione los cargos"
                                    options={roles.map(r => ({ value: r.id, label: r.nombre_visible }))}
                                    defaultValue={user_data?.roles?.map(r => ({ value: r.id, label: r.nombre_visible }))}
                                    onChange={result => {
                                        const value = result.map(r => {
                                            return { id: r.value, nombre_visible: r.label }
                                        })
                                        const name = "roles"
                                        setUserData({ ...user_data, [name]: value });
                                    }}
                                />
                                <FormError messages={errors.roles} />
                            </Col></>}
                            {editando && <Col md="6">
                                <FormGroup check >
                                    <Input type="checkbox" name="actualizar_contrasenia"
                                        checked={user_data.actualizar_contrasenia}
                                        onChange={handleValueChange}
                                    />
                                    <Label check>
                                        Actualizar contraseña
                                    </Label>
                                </FormGroup>
                            </Col>}
                        </Row>
                    </>
                }

                {
                    (!editando || user_data.actualizar_contrasenia) && <>
                        <Row>
                            <Col md="6">
                                <Label for="_password" required>Contraseña</Label>
                                <PasswordInput id="_password" name="password" required value={user_data.password ?? ""}
                                    onChange={handleValueChange}
                                    autoComplete="new-password"
                                />
                                <FormError messages={errors.password} />
                            </Col>
                            <Col md="6">
                                <Label for="_password2" required>Contraseña (confirmación)</Label>
                                <PasswordInput name='password_confirmation' id="_password2" required value={user_data.password_confirmation ?? ""}
                                    onChange={handleValueChange}
                                    autoComplete="new-password"
                                />
                                <FormError messages={errors.password_confirmation} />
                            </Col>
                        </Row>
                    </>
                }

                <Row>
                    <Col md="6" className="pt-2">
                        <Label required>Género</Label>
                        <FormGroup tag="fieldset" className="d-flex gap-2">
                            <FormGroup check>
                                <Input
                                    name="genero"
                                    type="radio"
                                    value="m"
                                    checked={user_data.genero === "m"}
                                    onChange={handleValueChange}
                                    required
                                />
                                {' '}
                                <Label check>
                                    M
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Input
                                    name="genero"
                                    type="radio"
                                    value="f"
                                    checked={user_data.genero === "f"}
                                    onChange={handleValueChange}
                                    required
                                />
                                {' '}
                                <Label check>
                                    F
                                </Label>
                            </FormGroup>

                        </FormGroup>
                        <FormError messages={errors.genero} />
                    </Col>
                    <Col md="6">
                        <div className="text-end pt-2">
                            {enviando?<SpinnerRight/>:<button className="btn btn-primary" type="submit">{editando ? 'Guardar' : 'Regístrarse'}</button>}
                        </div>
                    </Col>
                </Row>

                {!user && !creating_by_admin && !editando &&
                    <div className="text-center mt-3">
                        <p>¿Ya tienes una cuenta? <Link to="/login">Iniciar sesión</Link></p>
                    </div>
                }

            </form>
        </Contenedor>
    </AppLayout>
}

export default RegisterView;