import { Link } from "react-router-dom"
import useDeviceDetector from "../../hooks/useDeviceDetector"
import useUserService from "../../services/useUserService"
import useUserStore from "../../store/useUserStore"
import { DropDownMenu, DropDownMenuItem, NavLink, NavLinksContainer, ProfileMenu } from "./Elements"
import { hasRole } from "../../helpers/funciones"
import { ROL } from "../../utils/constantes"

const SideBar = ({ show = true }) => {
    const { isMobile } = useDeviceDetector()
    const { user_is_authenticated, current_user, removeUser } = useUserStore()
    const { logout } = useUserService()

    const is_admin = hasRole(current_user, ROL.admin)

    const handleLogout = async (ev) => {
        ev.preventDefault()
        var logged_out = await logout();
        if (logged_out) {
            removeUser()
            window.location.replace("/")
        }
    }
    return <>
        <div className={`sidebar ${show ? (isMobile ? "mobile-hide" : "") : "close"}`}>
            {user_is_authenticated && <ProfileMenu href="/profile" name={`${current_user.nombre} ${current_user.apellidos}`} />}
            {!user_is_authenticated && <ProfileMenu href="/login" name="Iniciar sesión" />}
            <NavLinksContainer>
                <NavLink href="/" iconClass="fa-solid fa-house">Inicio</NavLink>
                {(user_is_authenticated && is_admin) && <>
                    <DropDownMenu iconClass="fa-solid fa-users" title="Usuarios">
                        <DropDownMenuItem href="/admin/usuarios">Listado de usuarios</DropDownMenuItem>
                        <DropDownMenuItem href="/admin/usuarios/nuevo-usuario">Nuevo usuario</DropDownMenuItem>
                    </DropDownMenu>
                    <DropDownMenu iconClass="fa-solid fa-suitcase-rolling" title="Servicios">
                        <DropDownMenuItem href="/admin/servicios">Listado de servicios</DropDownMenuItem>
                        <DropDownMenuItem href="/admin/servicios/nuevo-servicio">Nuevo servicio</DropDownMenuItem>
                    </DropDownMenu>
                    <DropDownMenu iconClass="fa-solid fa-hotel" title="Departamentos">
                        <DropDownMenuItem href="/admin/departamentos">Listado de departamentos</DropDownMenuItem>
                        <DropDownMenuItem href="/admin/departamentos/nuevo-departamento">Nuevo departamento</DropDownMenuItem>
                    </DropDownMenu>
                </>
                }
                {/* <DropDownMenu title="Category" iconClass="fa-solid fa-box" >
                    <DropDownMenuItem href="#">HTML & CSS</DropDownMenuItem>
                    <DropDownMenuItem href="#">JavaScript</DropDownMenuItem>
                    <DropDownMenuItem href="#">PHP & MySQL</DropDownMenuItem>
                </DropDownMenu>
                <NavLink href="/analityc" iconClass="fa-solid fa-chart-line">Analytics</NavLink> */}



                {/* {user_is_authenticated && <ProfileMenu href="/profile" name={`${current_user.nombre} ${current_user.apellidos}`}/>}
                {!user_is_authenticated && <ProfileMenu href="/login" name="Iniciar sesión"/>} */}
                {user_is_authenticated && <div className="user-detail text-white logout">
                    <div className="d-flex gap-1">
                        <Link to="logout" onClick={handleLogout}>
                            <span className="icon"><i className="fa-solid fa-arrow-right-from-bracket" style={{ color: "red" }}></i></span>
                            <span className="text">Cerrar sesión</span>
                        </Link>
                    </div>
                </div>}



                {/* <li>
                    <div className="profile-details">
                        <div className="profile-content">
                            <img src="image/profile.jpg" alt="profileImg" />
                        </div>
                        <div className="name-job">
                            <div className="profile_name">Prem Shahi</div>
                            <div className="job">Web Desginer</div>
                        </div>
                        <i class='bx bx-log-out' ></i>
                    </div>
                </li> */}
            </NavLinksContainer>
        </div>
    </>
}
export default SideBar