// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.min.js'
import React, { useEffect } from 'react';
import './css/sidebar.css';
import router from './utils/routes';
import { RouterProvider } from 'react-router-dom';
import { Toaster } from 'sonner';
import { MetaProperty } from './components/layouts/Head';
import { registerSw } from './components/RegisterSw';
import 'mapbox-gl/dist/mapbox-gl.css';
import "react-image-gallery/styles/css/image-gallery.css";


import './css/main.css';
import './css/table_responsive.css';
function App() {
  useEffect(()=>{
    if(localStorage.getItem('user_token') && !localStorage.getItem("subscribed")===true){
      registerSw()
    }
  },[])
  return <React.Fragment>
    <Toaster richColors/>
    <MetaProperty name="vapid-key" content={process.env.REACT_APP_VAPID_PUBLIC_KEY} />
    <RouterProvider router={router} />
  </React.Fragment>
}

export default App;
