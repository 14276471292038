import { useEffect, useState } from "react";
import SideBar from "../navegacion/SideBar";

export default function AppLayout({ children }) {
    const [show_sidebar,setShowSidebar] = useState(true)
    /*useEffect(()=>{
        console.log("cargando posicion")
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition, showError);
        } else {
            alert("Geolocation is not supported by this browser.");
        }
        
        function showPosition(position) {
            var latitude = position.coords.latitude;
            var longitude = position.coords.longitude;
            console.log("Latitude: " + latitude + ", Longitude: " + longitude);
            // You can use latitude and longitude here as needed
        }
        
        function showError(error) {
            switch(error.code) {
                case error.PERMISSION_DENIED:
                    alert("User denied the request for Geolocation.");
                    break;
                case error.POSITION_UNAVAILABLE:
                    alert("Location information is unavailable.");
                    break;
                case error.TIMEOUT:
                    alert("The request to get user location timed out.");
                    break;
                case error.UNKNOWN_ERROR:
                    alert("An unknown error occurred.");
                    break;
            }
        }
        
    },[])*/
    return <><div className="container_">
        {/* <BarraNavegacion
            color="secondary" light={true} dark={true} full="true" expand="md" container="fluid" fixed="top"
        /> */}
        <SideBar show={show_sidebar} />
        {/* <div className="card principal-card">
        {children}
        </div> */}
        <section className="home-section">
            <div className="home-content">
                { <i className='bx bx-menu'onClick={()=>setShowSidebar(!show_sidebar)} ></i>}
                <span className="text">Turismo real</span>
            </div>
            <div className="p-2">
            {children}
            </div>
        </section>
    </div>
    </>
}