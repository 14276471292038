import { useEffect, useState } from "react"
import AppLayout from "../../components/layouts/AppLayout"
import Head from "../../components/layouts/Head"
import useUserService from "../../services/useUserService"
import { Link} from "react-router-dom"
import Swal from "sweetalert2"
import { Subtitulo } from "../../components/layouts/simpleLayouts"
import { toast } from "sonner"
import UsersTable from "../../components/users/UsersTable"
import Pagination from "../../components/generics/Pagination"
import { Input } from "reactstrap"

const UsersView = () => {
    const [usuarios, setUsuarios] = useState([])
    const [cargando, setCargando] = useState(false)
    const { listarUsuarios, actualizarEstadoUsuario } = useUserService()
    const [filtro, setFiltro] = useState("")
    //const { search } = useLocation();
    //const otros_parametros = new URLSearchParams(search);
    //console.log(otros_parametros.get("created"))
    function cargarUsuarios(texto_filtro = filtro) {
        setCargando(true)
        listarUsuarios({ all: true, filtro: texto_filtro }).then(result => {
            setUsuarios(result.data)
            setCargando(false)
        })
    }
    useEffect(() => {
        cargarUsuarios()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const handleEliminarUsuario = (id, nombre, apellido, status) => {
        Swal.fire({
            text: "¿Está seguro de querer desactivar al usuario " + nombre + " " + apellido + "?",
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Sí, desactivar",
            cancelButtonText: "No, cancelar",
            confirmButtonColor: "red",
            cancelButtonColor: "#336699",
        }).then(async (result) => {
            if (result.isConfirmed) {
                const usuario_actualizado = await actualizarEstadoUsuario({ id, status })
                if (usuario_actualizado) {
                    toast.success("El usuario se ha desactivado con éxito")
                    cargarUsuarios()
                }
            }
        })
    }
    const handleActivarUsuario = (id, nombre, apellido, status) => {
        Swal.fire({
            text: "¿Está seguro de querer activar al usuario " + nombre + " " + apellido + "?",
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Sí, activar",
            cancelButtonText: "No, cancelar",
            confirmButtonColor: "red",
            cancelButtonColor: "#336699",
        }).then(async (result) => {
            if (result.isConfirmed) {
                const usuario_actualizado = await actualizarEstadoUsuario({ id, status })
                if (usuario_actualizado) {
                    toast.success("El usuario se ha activado con éxito")
                    cargarUsuarios()
                }
            }
        })
    }
    const handleChangeUserStatus = (usuario, value) => {
        if (value) {
            handleActivarUsuario(usuario.id, usuario.nombre, usuario.apellidos, value)
        } else {
            handleEliminarUsuario(usuario.id, usuario.nombre, usuario.apellidos, value)
        }
    }
    const [usuarios_actuales, setUsuariosActuales] = useState([])

    function handlePageChange(inicio, fin) {
        setUsuariosActuales(usuarios.slice(inicio, fin))
    }
    function handleFiltrar(e) {
        e.preventDefault()
        cargarUsuarios()
    }
    function handleFiltroChange(e) {
        setFiltro(e.target.value)
        if (e.target.value === "") cargarUsuarios("")
    }
    return <AppLayout>
        <Head title="Listado de usuarios"></Head>
        <Subtitulo>Listado de usuarios</Subtitulo>
        <div>
            <div className="d-md-flex flew-wrap align-items-center justify-content-between">
                <form className="mb-3" onSubmit={handleFiltrar}>
                    <Input style={{ minWidth: "250px" }} type="text" name="search-input"
                        placeholder="" value={filtro}
                        onChange={handleFiltroChange} />
                    <em>Presione enter para buscar</em>

                </form>
                <div className="mb-3" style={{ minWidth: "129px" }}>
                    <Link to="/admin/usuarios/nuevo-usuario" className="btn btn-primary">Nuevo usuario</Link>
                </div>
            </div>
            <UsersTable users={usuarios_actuales} cargando={cargando} onBtnEditClick={(usuario, ev) => handleChangeUserStatus(usuario, ev.target.checked)} />
            {usuarios.length > 0 && <Pagination cargando={cargando} items_count={usuarios.length} onPageChange={handlePageChange} />}
        </div>
    </AppLayout>
}
export default UsersView