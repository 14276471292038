import { create } from "zustand"
import axios from '../lib/axios'
var current_user = null;
var current_token = null;
if (localStorage.getItem("user_token")) {
    current_token = localStorage.getItem("user_token")
    current_user = JSON.parse(localStorage.getItem("user_info"))
    axios.defaults.headers.common["Authorization"] = "Bearer " + current_token
}
const initialState = {
    user_is_authenticated: current_token != null,
    current_user: current_user,
    token: current_token,
    user_info:null,
}
const useUserStore = create((set) => ({
    ...initialState,
    setCurrentUser: (obj) => set(() => ({ current_user: obj, user_is_authenticated: true })),
    removeUser:()=> set(() =>({ current_user: null, user_is_authenticated: false,token:null})),
    setToken :function(token){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token
        return set(()=>({current_token:token}))
    },
    setUserInfo: function(info){
        return set(()=>({user_info:info}))
    }
}))

export default useUserStore;