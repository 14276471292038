import { useState } from "react"
import Label from "../layouts/forms/Label"
import { Col, Input, Row, Spinner } from "reactstrap"
import MapAutoCompletInput from "../layouts/forms/MapAutoCompleteInput"
import { Map, Marker } from 'react-map-gl';
import Select from 'react-select'
import AdvancedEditor from "../ckeditor/Ckeditor";
const ApartmentForm = ({ current_apartment, editing = false, onSubmit }) => {
    const [apartement_detail, setApartmentDetail] = useState(current_apartment || {
        nombre: "",
        "descripcion_breve": "",
        "descripcion_completa": "",
        "total_dormitorios": 1,
        "total_cocinas": 1,
        "total_banios": 1,
        "total_estacionamientos": 0,
        "precio_por_dia": "",
        "imagenes": [],
    })
    const [map_detail, setMapDetail] = useState(current_apartment?.ubicacion || {
        texto_busqueda: "",
        direccion: "",
        region: "",
        ciudad: "",
        codigo_postal: "",
        longitud: "",
        latitud: "",
    })
    const [enviando, setEnviando] = useState(false)
    const handleValueChange = (ev) => {
        var { name, type, value, checked } = ev.target
        if (type === "checkbox") {
            value = checked
        }
        setApartmentDetail({ ...apartement_detail, [name]: value });

    }
    const handleSubmit = async (ev) => {
        ev.preventDefault()
        setEnviando(true)
        const result = await onSubmit({ ...apartement_detail, "direccion": map_detail })
        setEnviando(false)
        console.log(result)
    }
    return <form onSubmit={handleSubmit}>
        <Row>
            <Col md="6" className="mb-3">
                <Label required={true}>Nombre</Label>
                <Input name="nombre" value={apartement_detail.nombre} onChange={handleValueChange} required />
            </Col>
            <Col md="6" className="mb-3">
                <Label required={true}>Estado</Label>
                <Select
                    // required
                    placeholder="Estado actual"
                />
            </Col>
        </Row>
        <Row>
            <Col className="mb-3">
                <Label required={true}>Descripción breve</Label>
                <textarea required name="descripcion_breve" className="form-control" rows={5} onChange={handleValueChange} />
            </Col>
        </Row>
        <Row>
            <Col>
                <Label required={true}>Descripción completa</Label>
                <AdvancedEditor value={apartement_detail.descripcion_completa}
                    setValue={(data) => setApartmentDetail({ ...apartement_detail, "descripcion_completa": data })} />
            </Col>
        </Row>
        <Row>
            <Col lg="8">
                <Label style={{ fontSize: "20px" }}>Ubicación del departamento</Label>
                <MapAutoCompletInput map_detail={map_detail} setMapDetail={setMapDetail} />
            </Col>
            {
                (map_detail.latitud && map_detail.longitud) && <Col lg="4">
                    <Map
                        mapboxAccessToken={process.env.REACT_APP_MAPBOX_PUBLIC_API_TOKEN}
                        initialViewState={{
                            longitude: map_detail.longitud,
                            latitude: map_detail.latitud,
                            zoom: 17
                        }}
                        style={{ width: "calc(100% - 40px)", maxWidth: "600px", height: 280, margin: "20px" }}
                        mapStyle="mapbox://styles/mapbox/streets-v9"
                    >
                        <Marker longitude={map_detail.longitud} latitude={map_detail.latitud} anchor="bottom" >
                            {/* <img src="./pin.png" /> */}
                            <i class="fa-solid fa-map-pin" style={{ color: "red", fontSize: "24px" }}></i>
                        </Marker>
                    </Map>
                </Col>
            }
        </Row>
        <div className="text-end mt-3">
            {enviando && <div style={{ maxWidth: "30px", "marginLeft": "auto" }}><Spinner /></div>}
            {!enviando && <button type="submit" className="btn btn-primary">Guardar</button>}
        </div>
    </form>
}
export default ApartmentForm