import { Table, Tbody, Td, Th, Thead, Tr } from "../../components/layouts/Table"
import { Spinner } from "../../components/layouts/bootstrap/SmallComponents"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPencil } from "@fortawesome/free-solid-svg-icons"
import { Link } from "react-router-dom"
const UsersTable = ({ users, cargando = false,onBtnEditClick }) => {
    return <Table>
        <Thead>
            <Tr className="bg-primary text-white">
                <Th style={{ width: "40px" }}>ID</Th>
                <Th>NOMBRE</Th>
                <Th>APELLIDO</Th>
                <Th>CORREO</Th>
                <Th style={{ width: "80px" }}>EDITAR</Th>
                <Th style={{ width: "80px" }}>ACTIVO</Th>
            </Tr>
        </Thead>
        <Tbody>
            {cargando ? <Tr><Td colSpan="6"><Spinner /></Td></Tr> :
                users.map((usuario) => {
                    return <Tr key={usuario.id}>
                        <Td cabecera="ID">{usuario.id}</Td>
                        <Td cabecera="NOMBRE">{usuario.nombre}</Td>
                        <Td cabecera="APELLIDOS">{usuario.apellidos}</Td>
                        <Td cabecera="CORREO">{usuario.email}</Td>
                        <Td cabecera="ACCIONES">
                            <Link to={`/admin/usuarios/${usuario.id}/editar-usuario`}>
                                <FontAwesomeIcon icon={faPencil}></FontAwesomeIcon>
                            </Link>
                        </Td>
                        <Td cabecera="ACTIVO"><div className="table-form-check"><div className="form-check ">
                            <input className="form-check-input" type="checkbox" value=""
                                id="check_activo" checked={usuario.active} onChange={ev=>onBtnEditClick(usuario,ev)} />
                        </div></div></Td>
                    </Tr>
                })}
        </Tbody>
    </Table>
}

export default UsersTable;