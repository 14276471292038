import AppLayout from "../components/layouts/AppLayout";
import Head from "../components/layouts/Head";
const NotFoundView = () => {
    return <AppLayout>
        <Head
            title="Recurso no encontrado"
        />
        <div className="alert alert-danger" style={{maxWidth:"500px",margin:"0 auto"}}>
            <p>Lo sentimos, el recurso que buscas no se ha encontrado</p>
        </div>
    </AppLayout>
}

export default NotFoundView;