import { useEffect, useState } from "react";
import { Col, FormGroup, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import Label from "../layouts/forms/Label";
import Select from 'react-select'
import AdvancedEditor from "../ckeditor/Ckeditor";
import NewServiceCategory from "./NewServiceCategory";
import useCategoryService from "../../services/useCategoryService";
import FormError from "../layouts/forms/FormError";
import { Link } from "react-router-dom";
import { ImageInput, ImageList, ImageView } from "../generics/Image";

const ServiceForm = ({ obj_service,tipos_de_costo, editMode = false, onImageSelected = () => { },onDelete=()=>{}, onSubmit, errors = [] }) => {
    const [service_data, setServiceData] = useState(obj_service || {
        "nombre": "",
        "categoria_id": "",
        "precio":null,
        "tipo_de_costo_id": null,
        "tipo_de_costo":null,
        "descripcion": "",
        "imagenes": []
    })
    const [categorias, setCategorias] = useState([])
    const { fetchCategories } = useCategoryService()
    const [enviando, setEnviando] = useState(false)
    const [modaliSOpen, setModalIsOpen] = useState(false)
    const toggle = () => setModalIsOpen(!modaliSOpen)
    async function listarCategorias() {
        try {
            const result = await fetchCategories({});
            setCategorias(result)
            console.log({ result })
        } catch (error) {
            console.log(error)
        }
    }
    async function handleSubmit(ev) {
        setEnviando(true)
        ev.preventDefault()
        await onSubmit(service_data)
        setEnviando(false)
    }
    function handleValueChange(ev) {
        ev.preventDefault()
        var { name, type, value, checked } = ev.target
        if (type === "checkbox") {
            value = checked
        }
        setServiceData({ ...service_data, [name]: value });
    }

    useEffect(() => {
        listarCategorias()
    }, [])
    return <div>
        <Modal isOpen={modaliSOpen}>
            <ModalHeader toggle={toggle}>Nueva categoría</ModalHeader>
            <ModalBody>
                <NewServiceCategory onSave={() => {
                    listarCategorias();
                    toggle();
                }} />
            </ModalBody>
        </Modal>
        <form onSubmit={handleSubmit}>
            <Row>
                <Col md="6">
                    <FormGroup>
                        <Label required>Nombre</Label>
                        <Input type="text" name="nombre" value={service_data.nombre}
                            onChange={handleValueChange} required
                        />
                        <FormError messages={errors.nombre} />
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <Label required>Categoría</Label>
                        <Select
                            required
                            placeholder="Seleccione una categoría"
                            defaultValue={service_data.categoria ? {
                                value: service_data.categoria.id,
                                label: service_data.categoria.nombre
                            } : null}
                            options={categorias.map(c => ({ value: c.id, label: c.nombre }))}
                            onChange={({ value }) => setServiceData({ ...service_data, "categoria_id": value })}
                        />
                        <FormError messages={errors.categoria} />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md="6">
                    <Label>Precio</Label>
                    <Input type="number" name="precio" value={service_data.precio}
                            onChange={handleValueChange}
                        />
                    <FormError messages={errors.precio} />
                </Col>
                <Col md="6">
                    <FormGroup>
                        <Label required ={service_data.precio != null && service_data.precio != ""}>Tipo de costo</Label>
                        <Select
                            required ={service_data.precio != null && service_data.precio != ""}
                            placeholder="Seleccione el tipo de costo"
                            defaultValue={service_data.tipo_de_costo ? {
                                value: service_data.tipo_de_costo.id,
                                label: service_data.tipo_de_costo.nombre_visible
                            } : null}
                            options={tipos_de_costo.map(c => ({ value: c.id, label: c.nombre_visible }))}
                            onChange={({ value }) => setServiceData({ ...service_data, "tipo_de_costo_id": value })}
                        />
                        <FormError messages={errors.tipo_de_costo_id} />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Label required>Descripción</Label>
                <AdvancedEditor value={service_data.descripcion}
                    onChange={des => setServiceData({ ...service_data, "descripcion": des })} />
                <FormError messages={errors.descripcion} />
            </Row>
            {
                editMode && <>
                    <Label>Imágenes</Label>
                    <ImageInput onChange={onImageSelected} />
                    <ImageList>
                        {
                            obj_service.imagenes.map(i=><ImageView imagen={i} key={i.id} onDelete={onDelete} />)
                        }
                        
                    </ImageList>
                    <FormError messages={errors.imagen} /></>
            }
            <div className="d-flex justify-content-end gap-1 mt-3 flex-wrap">
                <Link className="btn btn-secondary" to="/admin/servicios">Volver</Link>
                <button type="button" className="btn btn-success" onClick={toggle}>Nueva categoría</button>
                {enviando ? <Spinner /> : <button type="submit" className="btn btn-primary">Guardar</button>}
            </div>
        </form>
    </div>
}

export default ServiceForm;