import { faEnvelope, faEye, faEyeSlash, faLock } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { Input, InputGroup, InputGroupText } from "reactstrap"

const EmailInput = ({ ...props }) => {
    return <InputGroup>
        <InputGroupText><FontAwesomeIcon icon={faEnvelope} /></InputGroupText>
        <Input type="email" {...props} />
    </InputGroup>
}
const PasswordInput = ({ ...props }) => {
    const [show_password, setShowPassword] = useState(false)
    return <div className="password-input-container">
        <InputGroup>
            <InputGroupText><FontAwesomeIcon icon={faLock} /></InputGroupText>
            <Input  {...props} type={show_password ? "text" : "password"} />
        </InputGroup>
        <FontAwesomeIcon className="show-hide-password-icon" icon={show_password ? faEyeSlash : faEye} onClick={()=>setShowPassword(!show_password)}/>
    </div>
}

export {
    EmailInput, PasswordInput
}