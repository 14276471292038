import Axios from "axios";

const axios = Axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    withXSRFToken: true,
})
axios.defaults.headers.common["Accept"] = "application/json"
axios.interceptors.response.use(response =>{
    return response
},error=>{
    if(error.response){
       if(error.response.status === 403){
        window.location.replace("/403")
       }
       else if(error.response.status === 401){
        localStorage.removeItem("user_info")
        localStorage.removeItem("user_token")
        window.location.replace("/login")
       }
    }
    return Promise.reject(error) 
})

export default axios;