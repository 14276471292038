import { useEffect, useState } from "react";

function useDeviceDetector() {
    const [deviceType, setDeviceType] = useState("")
    const [isMobile,setIsMobile] = useState(window.innerWidth <= 768 || window.innerHeight <= 768);
    const [isTablet,setIsTablet] = useState(window.innerWidth > 768 && window.innerWidth <= 1024)
    const [isDesktop,setIsDesktop] = useState(window.innerWidth > 1024)
    useEffect(() => {
        function detectDevice() {
            const width = window.innerWidth
            const height = window.innerHeight
            const isMobile = width <= 768 || height <= 768;
            const isTablet = width > 768 && width <= 1024;
            const isDesktop = width > 1024;
            if (isMobile) {
                setDeviceType('mobile');
                setIsMobile(true);
                setIsTablet(false)
                setIsDesktop(false);
            } else if (isTablet) {
                setDeviceType('tablet');
                setIsMobile(false);
                setIsTablet(true)
                setIsDesktop(false);
            } else if (isDesktop) {
                setDeviceType('desktop');
                setIsMobile(false);
                setIsTablet(false)
                setIsDesktop(true);
            }
        }
        detectDevice();

        window.addEventListener("resize", detectDevice)
        return () => {
            window.removeEventListener("resize", detectDevice)
        }
    }, [])
    // function isMobile() {
    //     return window.innerWidth <= 768 || window.innerHeight <= 768;
    // }
    // function isTablet() {
    //     return window.innerWidth > 768 && window.innerWidth <= 1024;
    // }
    // function isDesktop() {
    //     return window.innerWidth > 1024;
    // }

    return {
        deviceType,
        isMobile,
        isTablet,
        isDesktop
    }
}
export default useDeviceDetector;