

const FormError = ({ messages }) => {
    if (!messages || messages.length === 0) return <></>
    return <div className="form-errors">
        {messages.map(message => <span key={message}>{message}</span>)}
    </div>
}


export default FormError;