import AppLayout from "../components/layouts/AppLayout";
import Head from "../components/layouts/Head";
import { FormGroup, Spinner } from "reactstrap";
import Label from "../components/layouts/forms/Label";
import { Contenedor, Subtitulo } from "../components/layouts/simpleLayouts";
import { useState } from "react";
import useUserService from "../services/useUserService";
import FormError from "../components/layouts/forms/FormError";
import { toast } from "sonner";
import { Link, useNavigate } from "react-router-dom";
import useUserStore from "../store/useUserStore";
import { EmailInput, PasswordInput } from "../components/layouts/forms/Inputs";


const Login = () => {
    const [correo, setCorreo] = useState("")
    const [password, setPassword] = useState("")
    const [errors, setErrors] = useState([])
    const [generic_message, setGenericMessage] = useState()
    const [cargando, setCargando] = useState(false)
    const { setCurrentUser, setToken } = useUserStore()

    const { login } = useUserService()
    const navigate = useNavigate()

    const handleSubmit = async (ev) => {
        setGenericMessage(null)
        ev.preventDefault();
        setCargando(true)
        const redirect_to = "/"
        const datos_formulario = {
            email: correo, password
        }

        const result = await login(datos_formulario)
        setCargando(false)
        if (result.data?.status_code === 422) {
            setGenericMessage(result.data.message)
        }
        else if (result.status === 422) {
            toast.error("Algunos campos no son válidos")
            setErrors(result.data.errors)
        } else {
            const { user, token } = result.data
            localStorage.setItem("user_info", JSON.stringify(user))
            localStorage.setItem("user_token", token)
            setCurrentUser(user)
            setToken(token)
            navigate(redirect_to)
        }


    }


    return <AppLayout>
        <Head
            title="Iniciar sesión"
        />
        <Contenedor width="500">
            <Subtitulo>Inicio de sesión</Subtitulo>
            <form onSubmit={handleSubmit} autoComplete="off">
                <div>
                    <FormGroup>
                        <Label required={true} htmlFor="text_correo">Correo:</Label>
                        <EmailInput required id="text_correo" value={correo}
                            onChange={ev => setCorreo(ev.target.value)}
                        />
                    </FormGroup>
                    <FormError messages={errors.email} />
                </div>
                <div>
                    <Label htmlFor="_password" required>Contraseña</Label>
                    <PasswordInput id="_password" required value={password}
                        onChange={ev => setPassword(ev.target.value)}
                        autoComplete="new-password"
                    />
                    <FormError messages={errors.password} />
                </div>
                <div className="text-end pt-2">
                    {cargando ? <div style={{ width: "30px", marginLeft: "auto" }}><Spinner /></div> : <button className="btn btn-primary" type="submit">Iniciar sesión</button>}
                </div>
                {generic_message && <div className="error-message">{generic_message}</div>}
                <div className="text-center mt-3">
                    <p>¿Todavía no tienes una cuenta? <Link to="/register">Regístrate</Link></p>
                </div>
            </form>
        </Contenedor>
    </AppLayout>
}

export default Login;