import React, { useState, useCallback, useEffect } from 'react';
import { AddressAutofill, AddressMinimap, useConfirmAddress, config } from '@mapbox/search-js-react';
import { Col, Input, Row } from 'reactstrap';
import Label from './Label';

import useDeviceDetector from '../../../hooks/useDeviceDetector';
const MapAutoCompletInput = ({ map_detail, setMapDetail }) => {
    const [showFormExpanded, setShowFormExpanded] = useState(false);
    const [showMinimap, setShowMinimap] = useState(false);
    const [feature, setFeature] = useState();
    const [showValidationText, setShowValidationText] = useState(false);
    const [token, setToken] = useState('');

    const { isMobile } = useDeviceDetector()



    function setDetail(key, value) {
        setMapDetail(map_detail => { return { ...map_detail, [key]: value } })
    }

    useEffect(() => {
        const accessToken = process.env.REACT_APP_MAPBOX_API_TOKEN;
        setToken(accessToken)
        config.accessToken = accessToken;
    }, [])

    const { formRef, showConfirm } = useConfirmAddress({
        minimap: true,
        skipConfirmModal: (feature) => {
            ['exact', 'high'].includes(feature.properties.match_code.confidence)
        }
    });

    const handleRetrieve = useCallback(
        (res) => {
            console.log({ res })
            const feature = res.features[0];
            console.log(feature)

            const coordenadas = feature.geometry.coordinates
            if (coordenadas) {
                const longitud = coordenadas[0]
                const latitud = coordenadas[1]
                setDetail("longitud", longitud)
                setDetail("latitud", latitud)
            }
            const propiedades = feature.properties
            setDetail("region", propiedades.address_level1)
            setDetail("ciudad", propiedades.address_level2)
            setDetail("direccion", propiedades.place_name)
            setDetail("codigo_postal", propiedades.postcode)
            setDetail("texto_busqueda", propiedades.matching_name)


            setFeature(feature);
            setShowMinimap(true);
            setShowFormExpanded(true);
        },
        [setFeature, setShowMinimap]
    );

    function handleSaveMarkerLocation(coordinate) {
        console.log(`Marker moved to ${JSON.stringify(coordinate)}.`)
    }

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        const result = await showConfirm();
        console.log({ result })
        if (result.type === 'nochange') submitForm();
    }, [showConfirm]);

    function submitForm() {
        setShowValidationText(true);
        setTimeout(() => {
            resetForm();
        }, 2500);
    }

    function resetForm() {
        const inputs = document.querySelectorAll("input");
        inputs.forEach(input => input.value = "");
        setShowFormExpanded(false);
        setShowValidationText(false);
        setFeature(null);
    }
    return <div style={{ border: "1px dashed #ccc", padding: "10px" }}>
        <div ref={formRef} onSubmit={handleSubmit}>
            <Row>
                <Col md="12">
                    <AddressAutofill accessToken={token} onRetrieve={handleRetrieve} options={{
                        language: 'es',
                        country: 'CL',
                    }}>
                        <Input
                            placeholder='Empieza escribiendo la dirección para buscar'
                            autoComplete="address-line1"
                            id="mapbox-autofill"
                        />
                    </AddressAutofill>


                </Col>
                {/* <Col>
                    {!showFormExpanded &&
                        <div
                            id="manual-entry"
                            className="w180 mt6 link txt-ms border-b color-gray color-black-on-hover"
                            onClick={() => setShowFormExpanded(true)}
                        >
                            Ingresar la dirección manualmente
                        </div>
                    }
                </Col> */}
            </Row>
            <Row>
                <Col md="8">
                    <Label required={true}>Dirección</Label>
                    <Input required value={map_detail.direccion} onChange={ev => setDetail("direccion", ev.target.value)} />
                </Col>
                <Col md="4">
                    <Label required={true}>Código postal</Label>
                    <Input required value={map_detail.codigo_postal} onChange={ev => setDetail("codigo_postal", ev.target.value)} />
                </Col>
            </Row>
            <Row>
                <Col md="6">
                    <Label required={true}>Región</Label>
                    <Input required value={map_detail.region} onChange={ev => setDetail("region", ev.target.value)} />
                </Col>
                <Col md="6">
                    <Label required={true}>Ciudad</Label>
                    <Input required value={map_detail.ciudad} onChange={ev => setDetail("ciudad", ev.target.value)} />
                </Col>
            </Row>

            <Row>
                <Col>
                    <Label required={true}>Latitud</Label>
                    <Input required value={map_detail.latitud} onChange={ev => setDetail("latitud", ev.target.value)} />
                </Col>
                <Col>
                    <Label required={true}>Longitud</Label>
                    <Input required value={map_detail.longitud} onChange={ev => setDetail("longitud", ev.target.value)} />
                </Col>
            </Row>
            
            <div style={{ display: showFormExpanded ? 'block' : 'none' }}>

                {/* <div className="row">
                    
                    <div
                        id="minimap-container"

                    >
                        <AddressMinimap
                            canAdjustMarker={true}
                            satelliteToggle={true}
                            feature={feature}
                            show={showMinimap}
                            onSaveMarkerLocation={handleSaveMarkerLocation}
                        />
                    </div>
                </div> */}
            </div>


            {/* Form buttons */}
            {/* {showFormExpanded &&
                <div className='text-end mt-3'>
                    <button type="submit" className="btn btn-sm btn-primary" id="btn-confirm">Confirmar</button>
                </div>
            } */}
        </div>
    </div>

}
export default MapAutoCompletInput;