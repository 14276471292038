
import AppLayout from "../components/layouts/AppLayout"
import Head from "../components/layouts/Head"

const HomeView = () => {
    return <AppLayout>
        <Head></Head>
        <p>Bienvenido</p>

    </AppLayout>
}

export default HomeView