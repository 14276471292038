import * as React from "react";
import UserManagementView from "../pages/UserManagementView"
import LoginView from "../pages/LoginView"
import UsersView from "../pages/users/UsersView";
import HomeView from "../pages/HomeView";
import {
  Route,
  createBrowserRouter, createRoutesFromElements,
} from "react-router-dom";
import ProtectedView from "../components/layouts/forms/ProtectedView";

import { ROL } from "./constantes";
import UserEditView from "../pages/users/UserEditView";
import NotAllowedView from "../pages/NotAllowedView";
import UserCreationView from "../pages/users/UserCreationView";
import NewApartmentView from "../pages/apartments/NewApartmentView";
import NewServiceView from "../pages/services/NewServiceView";
import ServiceListView from "../pages/services/ServicesListView";
import ServiceEditView from "../pages/services/ServiceEditView";
import ServiceDetailView from "../pages/services/ServiceDetailView";
import NotFoundView from "../pages/NotFoundView";
import { useLoadService, useServiceFormLoader } from "../loaders/ServicesLoader";

const adminRoutes = [
    {
      path: "/profile",
      element:<ProtectedView required_roles={[ROL.cliente,ROL.admin,ROL.functionario]}><UserEditView/></ProtectedView>
    },
    {
      path:"/admin/usuarios",
      element: <ProtectedView required_roles={[ROL.admin]}><UsersView/></ProtectedView>
    },
    {
      path:"/admin/usuarios/:id/editar-usuario",
      element: <ProtectedView required_roles={[ROL.admin]}><UserEditView/></ProtectedView>
    },
    {
      path:"/admin/usuarios/nuevo-usuario",
      element: <ProtectedView required_roles={[ROL.admin]}><UserCreationView/></ProtectedView>
    },
    {
      path:"/admin/servicios",
      element:<ProtectedView required_roles={[ROL.admin]}><ServiceListView/></ProtectedView>
    },
    {
      path:"/admin/servicios/nuevo-servicio",
      element:<ProtectedView required_roles={[ROL.admin]}><NewServiceView/></ProtectedView>,
      loader: useServiceFormLoader
    },
    {
      path:"/admin/servicios/:slug/editar-servicio",
      element:<ProtectedView required_roles={[ROL.admin]}><ServiceEditView/></ProtectedView>,
      loader: useServiceFormLoader
    },
    {
      path:"/admin/departamentos/nuevo-departamento",
      element:<ProtectedView required_roles={[ROL.admin]}><NewApartmentView/></ProtectedView>
    },
    
  ]

const publicRoutes = [
  {
    path: "/",
    element: <HomeView/>,
  },
  {
    path: "/403",
    element: <NotAllowedView/>,
  },
  {
    path: "/404",
    element: <NotFoundView/>,
  },
  {
    path: "/register",
    element: <UserManagementView />
  },
  {
    path: "/login",
    element: <LoginView />
  },
  {
    path:"/servicios/:slug",
    element:<ServiceDetailView/>
    ,loader:  useLoadService
  },
  {
    path:"*",
    element:<NotFoundView/>
  }
]
const allRoutes = [...adminRoutes,...publicRoutes]
const router = createBrowserRouter(allRoutes);
// const router = createBrowserRouter(createRoutesFromElements(allRoutes.map(r=>(
//   <Route path={r.path} element={r.element} loader={r.loader}/>
// ))))

export default router;