import axios from "../lib/axios"
const useUserService = () => {
    const registerUser = async ({as_admin, ...props }) => {
        
        try {
            await axios.get('/sanctum/csrf-cookie')
            var url = as_admin === true? '/api/users/store':"/api/register"
            const result = await axios.post(url, props)
            return result
        } catch (error) {
            console.log(error)
            return error.response
        }
    }

    const login = async ({ ...props }) => {
        await axios.get('/sanctum/csrf-cookie')
        try {
            const result = await axios.post("/api/login", props)
            return result
        } catch (error) {
            return error.response
        }
    }
    const logout = async () => {
        localStorage.removeItem("user_info")
        localStorage.removeItem("user_token")
        try {
            await axios.get('/api/logout')

        } catch (e) {
            console.log(e)
        }
        return true
    }

    const listarUsuarios = async ({ ...props }) => {
        var url = "/api/users?"
        Object.keys(props).forEach(key => url += `&${key}=${props[key]}`)
        try {
            const result = await axios.get(url)
            return await result.data
        } catch (error) {
            console.log(error)
            return error.response
        }
    }
    const getUserInfo = async () => {
        try {
            const result = await axios.get("/api/user")
            return result.data
        } catch (error) {
            console.log(error)
            return null;
        }
    }
    const getUser = async({...props})=>{
        var url = "/api/users/show?"
        Object.keys(props).forEach(key => url += `&${key}=${props[key]}`)
        try{
            const result = await axios.get(url)
            return result.data
        }catch(error){
            console.log(error)
            return null
        }
    }
    const actualizarEstadoUsuario = async ({ ...props }) => {
        try {
            const result = await axios.post("/api/users/update_status", props)
            return result.data
        } catch (error) {
            console.log(error)
        }
    }
    const updateUser = async ({ ...props }) => {
        try {
            const result = await axios.put("/api/users/update", props)
            return result
        } catch (error) {
            return error.response
        }
    }
    const listarRoles = async ({ ...props }) => {
        var url = "/api/roles?"
        Object.keys(props).forEach(key => url += `&${key}=${props[key]}`)
        try {
            const result = await axios.get(url)
            return await result.data
        } catch (error) {
            console.log(error)
            return error.response
        }
    }

    return {
        registerUser, login, logout, listarUsuarios, getUserInfo, actualizarEstadoUsuario,getUser,updateUser,
        listarRoles
    }
}

export default useUserService