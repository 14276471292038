import { Spinner } from "reactstrap";
import AppLayout from "../components/layouts/AppLayout";
import Head from "../components/layouts/Head";
import { Contenedor, Subtitulo } from "../components/layouts/simpleLayouts";


const LoadingView = () => {
    return <AppLayout>
        <Head
            title="Cargando"
        />
        <div style={{width:"20px",margin:"0 auto",margin:"0 auto"}}><Spinner/></div>
    </AppLayout>
}

export default LoadingView;