import { useEffect, useState } from "react"
import useUserService from "../../../services/useUserService"
import useUserStore from "../../../store/useUserStore"
import LoadingView from "../../../pages/LoadingView"
import NotAllowedView from "../../../pages/NotAllowedView"

const ProtectedView = ({ required_roles, children }) => {
    const [acceso_autorizado, setAccesoAutorizado] = useState(false)
    const { user_info, setUserInfo } = useUserStore()
    const { getUserInfo } = useUserService()
    const [cargando, setCargando] = useState(true)

    const validarRoles = (user_data) => {
        if (user_data.roles.some(r => required_roles.find(x => x === r.nombre))) {
            setAccesoAutorizado(true)
        } else {
            setAccesoAutorizado(false)
        }
        setCargando(false)
    }

    async function validarUsuarioOnline() {
        var datos = await getUserInfo()
        if (datos != null) {
            setUserInfo(datos)
            validarRoles(datos)
        } else {
            setAccesoAutorizado(false)
            setCargando(false)
        }
    }
    useEffect(() => {
        if (user_info == null) {
            validarUsuarioOnline()

        } else {
            validarRoles(user_info)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    if (cargando) {
        return <LoadingView />
    } else if (acceso_autorizado) {
        return children
    } else {
        return <NotAllowedView />
    }
}

export default ProtectedView