import { useEffect, useRef } from "react"
import { Card } from "reactstrap"

const Contenedor = ({width=null,heigth=null,children,...props})=>{
    const referencia = useRef()
    useEffect(()=>{
        if(referencia.current){
            if(width){
                referencia.current.style = `width: ${width}px;margin:0 auto;`
            }
            if(heigth) referencia.current.style.heigth = heigth+"px"
        }
    },[referencia,heigth,width])
    return <div ref={referencia} className="contenedor-padre"> <Card className="contenedor" {...props}>{children}</Card></div>
}

const Subtitulo = ({children}) =>{
    return <>
    <h3>{children}</h3>
    <hr/>
    </>
}

export {
    Contenedor,Subtitulo
}