import { useState } from "react"
import AppLayout from "../../components/layouts/AppLayout"
import Head from "../../components/layouts/Head"
import { Contenedor, Subtitulo } from "../../components/layouts/simpleLayouts"
// import useServicioService from "../../services/useServicioService"
import { Link, useLoaderData, useNavigate, useParams } from "react-router-dom"
import ImageGallery from "react-image-gallery";
import useDeviceDetector from "../../hooks/useDeviceDetector"


export default function ServiceDetailView({ comeBackUrl = "/admin/servicios",...props }) {
    const {currentService} = useLoaderData()
    // const [currentService, setCurrentService] = useState();
    // const { showService } = useServicioService()
    const { isMobile } = useDeviceDetector()
    // const navigate = useNavigate()
    // const { slug } = useParams()
    // useState(() => {
    //     showService({ slug }).then(result => {
    //         if (result) {
    //             setCurrentService(result)
    //         } else {
    //             navigate("/404")
    //         }
    //     })
    // }, [])


    return <AppLayout>
        <Head title={currentService?.nombre} description={currentService?.descripcion} />
        <Contenedor>
            <Subtitulo>{currentService ? currentService?.nombre : "cargando..."}</Subtitulo>
            {currentService && <>
                {currentService.imagenes.length>0 && <div className="imgenes-servicio mb-3">
                    <ImageGallery thumbnailPosition={isMobile ? "bottom" : "left"} items={currentService.imagenes.map(i => {
                        return {
                            original: i.url,
                            thumbnail: i.url
                        }
                    })} />
                </div>}
                <div dangerouslySetInnerHTML={{ __html: currentService.descripcion }}></div>
            </>
            }
            <div className="text-end">
                <Link className="btn btn-secondary" to={comeBackUrl}>Volver</Link>
            </div>
        </Contenedor>
    </AppLayout>
}