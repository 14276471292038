import { useEffect } from "react";
import { APP_DESCRIPTION, APP_NAME } from "../../utils/constantes";


function createMetaElement({ name, content, property }){
    var meta = document.createElement("meta")
    if (name) meta.setAttribute("name", name)
    if (property) meta.setAttribute("property", property)
    if (content) meta.setAttribute("content", content)
    return meta
}

const Head = ({ title = "", description = APP_DESCRIPTION }) => {
    useEffect(() => {
        const title_element = createMetaElement({property:"og:title",content:title})
        const description_element = createMetaElement({property:"og:description",content:description.slice(0,300)})

        document.head.appendChild(title_element)
        document.head.appendChild(description_element)

        document.title = title !=="" ? APP_NAME + " - " + title : APP_NAME
        return () => {
            document.title = APP_NAME
            document.head.removeChild(title_element)
            document.head.removeChild(description_element)
        }
    }, [title,description])
    return <></>
}

const MetaProperty = ({ name, content, property }) => {
    var cabecera = document.querySelector("head")
    var meta = document.createElement("meta")
    if (name) meta.setAttribute("name", name)
    if (property) meta.setAttribute("property", property)
    if (content) meta.setAttribute("content", content)
    cabecera.appendChild(meta)
    useEffect(() => {
        return () => {
            cabecera.removeChild(meta)
        }
    }, [])
    return <></>
}
export { MetaProperty }
export default Head;