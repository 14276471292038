import { useId } from "react"
import { Spinner } from "reactstrap"
const Checkbox = ({ value, setValue, children }) => {
    const id = useId()
    return <div className="form-check">
        <input className="form-check-input" type="checkbox" value={value} id={id} onChange={ev => setValue(ev.target.checked)} />
        <label className="form-check-label" for={id}>
            {children}
        </label>
    </div>
}
const SpinnerRight = () => <div style={{ width: "50px", marginLeft: "auto" }}><Spinner /></div>
const SuccessMessage = ({ children }) => {
    return <div style={{
        border: "1px solid", borderLeftWidth: "3px",
        borderColor: "#339966", padding: "0 10px", borderRadius: "5px"
    }}>{children}</div>
}
const ErrorMessage = ({ children }) => {
    return <div style={{
        border: "1px solid", borderLeftWidth: "3px",
        borderColor: "red", padding: "0 10px", borderRadius: "5px"
    }}>{children}</div>
}

export {
    Checkbox, SpinnerRight, SuccessMessage,ErrorMessage
}