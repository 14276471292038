import useDeviceDetector from "../../hooks/useDeviceDetector";
import { useEffect, useState } from "react";

function Pagination({ per_page=10,per_page_mobile=1,items_count, onPageChange,cargando }) {
    const {isMobile} = useDeviceDetector()

    const current_per_page = isMobile? per_page_mobile:per_page
    const [currentPage,setCurrentPage] = useState(1)
    const total_pages = Math.ceil(items_count/current_per_page)
    const botones =[]
    var primer_indice;
    var ultimo_indice;
    if(currentPage === 1){
        primer_indice = 1;
        ultimo_indice = Math.min(3,total_pages);
    }else if(currentPage === total_pages){
        primer_indice = Math.max(currentPage -2, 1)
        ultimo_indice = currentPage
    }
    else{
        primer_indice = currentPage -1;
        ultimo_indice = Math.min(total_pages,currentPage+1)
    }
    for(let i=primer_indice;i<ultimo_indice+1;i++){
        botones.push(i)
    }
    useEffect(()=>setCurrentPage(1),[cargando])

    useEffect(()=>{
        if(onPageChange){
            var inicio
            if(currentPage === 1){
                inicio = 0;
            }else{
                inicio  = (currentPage - 1) * current_per_page
            }
            onPageChange(inicio,inicio + current_per_page)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentPage,cargando])
    function btnAnteriorClick(){
        if(currentPage >1) setCurrentPage(currentPage - 1)
    }
    function btnSiguienteClick(){
        if(currentPage <total_pages) setCurrentPage(currentPage +1)
    }
    
    const mostrando_inicio = ((currentPage -1)* current_per_page)+ 1
    const mostrando_fin =Math.min( (currentPage ) * current_per_page,items_count)
    return <>
    <p>Mostrando {mostrando_inicio}-{mostrando_fin} de {items_count}</p>
    <div className="pagination-container">
        <span onClick={btnAnteriorClick} className={`pagination-btn pagination-btn-anterior ${currentPage>1?"":"disabled"}`}>Anterior</span>
            {
               botones.map(n=><span key={n} onClick={()=>setCurrentPage(n)} className={`pagination-btn ${n === currentPage?"active":""}`}>{n}</span>) 
            }
            <span onClick={btnSiguienteClick} className={`pagination-btn pagination-btn-siguiente ${currentPage <total_pages?"":"disabled"}`}>Siguiente</span>
    </div></>
}
export default Pagination
