// import { useNavigate } from "react-router-dom"
import useServicioService from "../services/useServicioService"
import axios from "../lib/axios"

async function useLoadService({ params, request }) {
    const { slug } = params
    const { showService } = useServicioService()
    const result = await showService({ slug })
    if (result) {
        return { currentService: result }
    } else {
        window.location.replace("/404")
        return { currentService: null }
    }
}

async function useServiceFormLoader({ params, request }) {
    const url = "/api/services/tipos_de_costos"
    try {
        const result = await axios.get(url)
        return { tipos_de_costos:result.data }
    } catch (err) {
        console.log(err)
        return { tipos_de_costos:[] }
    }
}

export {
    useLoadService, useServiceFormLoader
}