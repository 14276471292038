const Spinner = ({ color }) => {

    color="primary"
    return <div className={`spinner-border ${color ? "text-" + color : ""}`} role="status">
        <span className="sr-only">Loading...</span>
    </div>
}

export {
    Spinner
}