import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const AdvancedEditor = ({ value, onChange = () => { } }) => {
    // Configuración del toolbar
    const editorConfiguration = {
        toolbar: [
            'heading', '|',
            'bold', 'italic', '|',
            'link', '|',
            'bulletedList', 'numberedList', '|',
            'indent', 'outdent', '|',
            'undo', 'redo'
        ],
        language: "es"
    };
    return (
        <div className="advanced-editor-container">
            {/* <h2>Using CKEditor&nbsp;5 build in React</h2> */}
            <CKEditor
                editor={ClassicEditor}
                config={editorConfiguration}
                data={value}
                onReady={editor => {
                    // You can store the "editor" and use when it is needed.
                    // console.log( 'Editor is ready to use!', editor );
                }}
                onChange={(event, editor) => {
                    onChange(editor.getData());
                }}
                onBlur={(event, editor) => {
                    // console.log( 'Blur.', editor );
                }}
                onFocus={(event, editor) => {
                    // console.log( 'Focus.', editor );
                }}
            />
        </div>
    );

}
export default AdvancedEditor;

