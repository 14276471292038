import { useState } from "react"
import AppLayout from "../../components/layouts/AppLayout"
import Head from "../../components/layouts/Head"
import { Contenedor, Subtitulo } from "../../components/layouts/simpleLayouts"
import ServiceForm from "../../components/services/ServiceForm"
import useServicioService from "../../services/useServicioService"
import Swal from "sweetalert2"
import { useLoaderData, useNavigate } from "react-router-dom"


export default function NewServiceView(){
    const [errors,setErrors] = useState([])
    const {storeService} = useServicioService()
    const navigate = useNavigate()
    const {tipos_de_costos} = useLoaderData()
    async function submit(data){
        setErrors([])
        const result = await storeService(data)
        if(result.status === 200){
            Swal.fire({
                text:"El servicio se ha creado con éxito",
                icon:"success"
            }).then(()=>{
                navigate(`/admin/servicios/${result.data.slug}/editar-servicio`)
            })
        }else{
            console.log(result)
            setErrors(result.data.errors)
        }
        console.log(data)
    }
    return <AppLayout>
        <Head title="Nuevo servicio" />
        <Contenedor>
        <Subtitulo>Nuevo Servicio</Subtitulo>
        <ServiceForm tipos_de_costo={tipos_de_costos} errors={errors} onSubmit={submit}/>
        </Contenedor>
    </AppLayout>
}