import axios from "../lib/axios"
export default function useCategoryService(){
    async function storeCategory({...props}){
        try{
            const result = await axios.post("/api/categories/store",props)
            return result;
        }catch(err){
            console.log(err)
            return err.response
        }
    }
    async function fetchCategories ({ ...props }){
        var url = "/api/categories?"
        Object.keys(props).forEach(key => url += `&${key}=${props[key]}`)
        try {
            const result = await axios.get(url)
            return await result.data
        } catch (error) {
            console.log(error)
            return error.response
        }
    }
    return {
        storeCategory,fetchCategories
    }
}