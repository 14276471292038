import { useState } from "react"
import { FormGroup, Input, Label } from "reactstrap"
import FormError from "../layouts/forms/FormError"
import { ErrorMessage, SpinnerRight, SuccessMessage } from "../layouts/forms/Layouts"
import useCategoryService from "../../services/useCategoryService"
import Swal from "sweetalert2"

export default function NewServiceCategory({ onSave = () => { } }) {
    const [nombre, setNombre] = useState("")
    const tipo = "servicio"
    const [errors, setErrors] = useState([])
    const [success_message, setSuccessMessage] = useState(null)
    const [error_message, setErrorMessage] = useState(null)
    const [cargando, setCargando] = useState(false)
    const { storeCategory } = useCategoryService()
    async function handleSubmit(e) {
        e.preventDefault()
        console.log("enviando")
        setCargando(true)
        setSuccessMessage(null)
        setErrorMessage(null)
        const result = await storeCategory({nombre,tipo})
        setErrors([])
        if(result.status === 200){
            Swal.fire({
                text:"La categoría se ha creado con éxito"
            }).then(()=>{
                onSave();
            })
            setNombre("")
        }else if(result.status === 422){
            setErrors(result.data.errors)
        }
        console.log({result})
        setCargando(false)
    }
    return <form onSubmit={handleSubmit}>
        <FormGroup>
            <Label required>Nombre</Label>
            <Input type="text" name="nombre" value={nombre}
                onChange={ev => setNombre(ev.target.value)} required
            />
            <FormError messages={errors.nombre} />
        </FormGroup>
        <div className="text-end my-3">
            {cargando ? <SpinnerRight /> : <button type="submit" className="btn btn-success">Guardar</button>}
        </div>
        {success_message && <SuccessMessage>{success_message}</SuccessMessage>}
        {error_message && <ErrorMessage>{error_message}</ErrorMessage>}
    </form>
}