import { useState } from "react"
import { Link } from "react-router-dom"

const NavLinksContainer = ({ children }) => {
    return <ul className="nav-links">
        {children}
    </ul>
}

const DropDownMenu = ({ title, iconClass, children }) => {
    const [show_menu, setShowMenu] = useState(false)
    return <li className={`iocn-link ${show_menu ? "showMenu" : ""}`}>
        <div className={`iocn-link`}  onClick={() => setShowMenu(!show_menu)} >
            <Link to="#">
                <i>
                    <i className={iconClass} ></i>
                </i>
                <span className="link_name">{title}</span>
            </Link>
            <i className='bx bxs-chevron-down arrow'></i>
        </div>
        <ul className="sub-menu">
            <li><Link className="link_name" href="#">{title}</Link></li>
            {children}
        </ul>
    </li>
}

const DropDownMenuItem = ({ href, children }) => {
    return <li><Link to={href}>{children}</Link></li>
}

const NavLink = ({ href, iconClass, children }) => {
    return <li>
        <Link to="#">
            <i>
                <i className={iconClass} ></i>
            </i>
            <Link className="link_name" to={href}>{children}</Link>
        </Link>
        <ul className="sub-menu blank">
            <li><Link className="link_name" to={href}>{children}</Link></li>
        </ul>
    </li>
}
const ProfileMenu = ({href,name}) => {
    return <div className="user-detail text-white">
        <div title="Editar tu perfil">
            <Link to={href}>
            <span className="icon"><i className="fa-solid fa-user"></i></span>
            <span className="text" style={{fontSize:"16px"}}>{name}</span>
            </Link>
        </div>
    </div>
}

export {
    NavLinksContainer, DropDownMenu, DropDownMenuItem, NavLink,ProfileMenu
}