const Table = ({children,...props})=>{
    return <div className="custom-table">
        <table {...props}>{children}</table>
    </div>
}
const Thead = ({children,...props})=> <thead {...props}>{children}</thead>
const Th = ({children,...props})=> <th {...props}>{children}</th>
const Tr = ({children,...props})=> <tr {...props}>{children}</tr>
const Tbody = ({children,...props})=> <tbody {...props}>{children}</tbody>
const Td = ({cabecera,children,...props})=> <td data-label={cabecera+":"} {...props}>{children}</td>

export {
    Table,Thead,Th,Tr,Tbody,Td
}