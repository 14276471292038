import { useEffect, useState } from "react"
import AppLayout from "../../components/layouts/AppLayout"
import Head from "../../components/layouts/Head"
import { Contenedor, Subtitulo } from "../../components/layouts/simpleLayouts"
import useServicioService from "../../services/useServicioService"
import { Table, Tbody, Td, Th, Thead, Tr } from "../../components/layouts/Table"
import { Spinner } from "reactstrap"
import Pagination from "../../components/generics/Pagination"
import { Link } from "react-router-dom"
import Swal from "sweetalert2"
import { toast } from "sonner"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faPencil } from "@fortawesome/free-solid-svg-icons"

export default function NewServiceView() {
    const { fetchServices, actualizarEstadoServicio } = useServicioService()
    const [servicios, setServicios] = useState([])
    const [cargando, setCargando] = useState(false)

    async function cargarServicios() {
        setCargando(true)
        const data = await fetchServices({})
        setServicios(data)
        setCargando(false)
    }
    const handleEliminarServicio = (id, status) => {
        Swal.fire({
            text: "¿Está seguro de querer desactivar este servicio?",
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Sí, desactivar",
            cancelButtonText: "No, cancelar",
            confirmButtonColor: "red",
            cancelButtonColor: "#336699",
        }).then(async (result) => {
            if (result.isConfirmed) {
                const servicio_actualizado = await actualizarEstadoServicio({ id, status })
                if (servicio_actualizado) {
                    toast.success("El servicio se ha desactivado con éxito")
                    cargarServicios()
                }
            }
        })
    }
    const handleActivarServicio = (id, status) => {
        Swal.fire({
            text: "¿Está seguro de querer activar este servicio?",
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Sí, activar",
            cancelButtonText: "No, cancelar",
            confirmButtonColor: "red",
            cancelButtonColor: "#336699",
        }).then(async (result) => {
            if (result.isConfirmed) {
                const servicio_actualizado = await actualizarEstadoServicio({ id, status })
                if (servicio_actualizado) {
                    toast.success("El servicio se ha activado con éxito")
                    cargarServicios()
                }
            }
        })
    }
    const handleChangeServicioStatus = (servicio, value) => {
        if (value) {
            handleActivarServicio(servicio.id, value)
        } else {
            handleEliminarServicio(servicio.id, value)
        }
    }
    useEffect(() => {
        cargarServicios()
    }, [])

    const [servicios_actuales, setServiciosActuales] = useState([])
    function handlePageChange(inicio, fin) {
        setServiciosActuales(servicios.slice(inicio, fin))
    }
    return <AppLayout>
        <Head title="Listado de servicios" />
        <Contenedor>
            <Subtitulo>Listado de servicios</Subtitulo>
            <div className="text-end mb-1">
                <Link to="/admin/servicios/nuevo-servicio" className="btn btn-primary">Nuevo servicio</Link>
            </div>
            <Table>
                <Thead>
                    <Tr className="bg-primary text-white">
                        <Th style={{ width: "40px" }}>ID</Th>
                        <Th>NOMBRE</Th>
                        <Th>CATEGORÍA</Th>
                        <Th style={{ width: "80px" }}>VER</Th>
                        <Th style={{ width: "80px" }}>EDITAR</Th>
                        <Th style={{ width: "80px" }}>ACTIVO</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        cargando ? <Tr><td colSpan="6"><Spinner /></td></Tr> : servicios_actuales.map(s => {
                            return <Tr key={s.id}>
                                <Td cabecera="ID">{s.id}</Td>
                                <Td cabecera="NOMBRE">{s.nombre}</Td>
                                <Td cabecera="CATEGORÍA">{s.categoria.nombre}</Td>
                                <Td cabecera="VER">
                                    <Link to={`/servicios/${s.slug}`}>
                                        <FontAwesomeIcon icon={faEye}></FontAwesomeIcon>
                                    </Link>
                                </Td>
                                <Td cabecera="EDITAR">
                                    <Link to={`/admin/servicios/${s.slug}/editar-servicio`}>
                                        <FontAwesomeIcon icon={faPencil}></FontAwesomeIcon>
                                    </Link>
                                </Td>
                                <Td cabecera="ACTIVO"><div className="table-form-check"><div className="form-check ">
                                    <input className="form-check-input" type="checkbox" value=""
                                        id="check_activo" checked={s.active} onChange={ev => handleChangeServicioStatus(s, ev.target.checked)} />
                                </div></div></Td>
                            </Tr>
                        })
                    }
                </Tbody>
            </Table>
            {servicios.length > 0 && <Pagination cargando={cargando} items_count={servicios.length} onPageChange={handlePageChange} />}
        </Contenedor>
    </AppLayout>
}