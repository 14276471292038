import axios from "../lib/axios"
export default function useServicioService(){
    async function storeService({...props}){
        try{
            const result = await axios.post("/api/services/store",props)
            return result;
        }catch(err){
            console.log(err)
            return err.response
        }
    }
    async function updateService({...props}){
        try{
            const result = await axios.post("/api/services/update",props)
            return result;
        }catch(err){
            return err.response
        }
    }
    async function fetchServices ({ ...props }){
        var url = "/api/services?"
        Object.keys(props).forEach(key => url += `&${key}=${props[key]}`)
        try {
            const result = await axios.get(url)
            return await result.data
        } catch (error) {
            console.log(error)
            return error.response
        }
    }
    async function showService ({ ...props }){
        var url = "/api/services/show?"
        Object.keys(props).forEach(key => url += `&${key}=${props[key]}`)
        try {
            const result = await axios.get(url)
            return await result.data
        } catch (error) {
            return null
        }
    }
    const actualizarEstadoServicio = async ({ ...props }) => {
        try {
            const result = await axios.post("/api/services/update_status", props)
            return result.data
        } catch (error) {
            console.log(error)
        }
    }
    async function uploadImage({imagen,...props}){
        try{
            const form_data = new FormData()
            form_data.append("imagen", imagen)
            Object.keys(props).forEach(key=>form_data.append(key,props[key]))
            const result = await axios.post("/api/services/upload_image",form_data)
            return result;
        }catch(err){
            console.log(err)
            return err.response
        }
    }
    async function deleteImage({...props}){
        try{
            const result = await axios.post("/api/services/delete_image",props)
            return result;
        }catch(err){
            console.log(err)
            return err.response
        }
    }
    return {
        storeService,updateService,fetchServices,showService,actualizarEstadoServicio,uploadImage,deleteImage
    }
}